import { OrderType } from '@finance/useCases/OperationLineValues.type';
import {
  CartSimulateParams,
  FormatsDateChanged,
  OrderSubmitParams,
  ParamsInvoiceSelection,
  TypesOfVisibilitiesStepTwo,
} from './types';
import tracker from '../../../../helpers/tracker';
import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';

export const eventState = {
  eventID: new Date().valueOf(),
};

export const setNewEventId = () => {
  eventState.eventID = new Date().valueOf();
};

export const clearEventId = () => {
  eventState.eventID = null;
};

const { eventID } = eventState;

export const trackFilterUsed = (payload: { filter_used: string }) => {
  tracker.event(SEGMENT_EVENTS.AR_FILTER_USED, {
    id: eventID,
    ...payload,
  });
};

export const trackFilterNoResults = (searchText: string) => {
  tracker.event(SEGMENT_EVENTS.AR_SEARCH_NO_RESULTS, {
    id: eventID,
    search_text: searchText,
  });
};

export const trackRefreshInvoices = () => {
  tracker.event(SEGMENT_EVENTS.AR_REFRESH_INVOICES, {
    id: eventID,
  });
};

export const trackLegalDocumentDownloaded = (documentTitle: string) => {
  tracker.event(SEGMENT_EVENTS.LEGAL_DOCUMENT_DOWNLOAD, {
    id: eventID,
    document_title: documentTitle,
  });
};

export const trackViewTab = (tab: string) => {
  const tabs = {
    prontopago: SEGMENT_EVENTS.AR_VIEW_PP,
    directo: SEGMENT_EVENTS.AR_VIEW_FD,
    [OrderType.DIRECT_FINANCING]: SEGMENT_EVENTS.AR_VIEW_FD,
    [OrderType.EARLY_PAYMENT]: SEGMENT_EVENTS.AR_VIEW_PP,
  };

  setNewEventId();

  tracker.event(tabs[tab], { id: eventID });
};

export const orderStarted = ({
  selectedCartInvoices,
  simulation,
  availableInvoicesCount,
  orderType,
  source,
}: CartSimulateParams) => {
  tracker.event(SEGMENT_EVENTS.ORDER_STARTED, {
    id: eventID,
    simulation,
    selectedCartInvoices,
    extend: {
      orderType,
      source,
      availableInvoicesCount,
      availableInvoicesAmount: 0,
    },
  });
};

export const orderSubmitedSuccess = ({
  simulation,
  selectedCartInvoices,
  availableInvoicesCount,
  orderId,
  businessId,
  isModified,
  orderType,
  source,
}: OrderSubmitParams) => {
  tracker.event(SEGMENT_EVENTS.ORDER_SUBMIT_SUCCESS, {
    id: eventID,
    extend: {
      simulation,
      selectedInvoices: selectedCartInvoices,
      orderType,
      source,
      availableInvoicesCount,
      availableInvoicesAmount: 0,
      id: orderId,
    },
    businessId,
    isModified,
  });
};

export const trackBackToStepOne = (button_used: string) => {
  tracker.event(SEGMENT_EVENTS.AR_BACK_TO_STEP_ONE, {
    id: eventID,
    button_used,
  });
};

export const viewModalOtherOrderType = ({
  to,
  from,
}: {
  to: `${OrderType}`;
  from: `${OrderType}`;
}) => {
  tracker.event(SEGMENT_EVENTS.AR_REDIRECT_OTHER_PRODUCT, {
    id: eventID,
    from,
    to,
  });
};

export const viewModalAndChangeOrderType = ({
  to,
  from,
}: {
  to: `${OrderType}`;
  from: `${OrderType}`;
}) => {
  tracker.event(SEGMENT_EVENTS.AR_REDIRECT_CONFIRMATION, {
    id: eventID,
    from,
    to,
  });
};

export const searchUsed = () => {
  tracker.event(SEGMENT_EVENTS.AR_SEARCH_USED, {
    id: eventID,
  });
};

export const trackInvoicesSelection = ({
  action,
  invoice_count,
  folios,
  currentPage,
}: ParamsInvoiceSelection) => {
  tracker.event(SEGMENT_EVENTS.AR_INVOICE_SELECTION, {
    id: eventID,
    action,
    invoice_count,
    folios,
    current_page: currentPage,
  });
};

export const invoiceChangeDate = ({
  format,
  term,
}: {
  format: `${FormatsDateChanged}`;
  term: number;
}) => {
  tracker.event(SEGMENT_EVENTS.AR_DATE_CHANGED, {
    id: eventID,
    format,
    term,
  });
};

export const invoiceDeleted = ({ rate }: { rate: number }) => {
  tracker.event(SEGMENT_EVENTS.AR_INVOICE_DELETED, {
    id: eventID,
    rate,
  });
};

export const trackPfxUpload = (button_used: string) => {
  tracker.event(SEGMENT_EVENTS.AR_UPLOAD_PFX, {
    id: eventID,
    button_used,
  });
};

export const trackerViewOnlyStepTwo = () => {
  tracker.event(SEGMENT_EVENTS.AR_VIEW_DIRECT_STEP_TWO, {
    id: eventID,
  });
};

export const trackerCreateOrderByUrl = () => {
  tracker.event(SEGMENT_EVENTS.AR_CREATE_ORDER_BY_URL, {
    id: eventID,
  });
};

export const trackerStepTwoInvoicesNotFinanciables = () => {
  tracker.event(SEGMENT_EVENTS.AR_STEP_TWO_INVOICES_NOT_FINANCIABLES, {
    id: eventID,
  });
};

export const trackerVisiblityStepTwo = (
  typeOfVisibility: `${TypesOfVisibilitiesStepTwo}`
) => {
  tracker.event(SEGMENT_EVENTS.AR_CHANGE_VISIBILITY_STEP_TWO, {
    id: eventID,
    type_of_visibility: typeOfVisibility,
  });
};

export const trackerDownloadSimulationPdf = () => {
  tracker.event(SEGMENT_EVENTS.AR_DOWNLOAD_ORDER_PDF, {
    id: eventID,
  });
};

export const trackDownloadCsvButtonUsed = (payload: {
  download_button_used: string;
}) => {
  tracker.event(SEGMENT_EVENTS.AR_DOWNLOAD_CSV_BUTTON_USED, {
    id: eventID,
    ...payload,
  });
};
