import { NextRouter, useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Loader } from 'components';
import { useFeatureFlags } from '@payments/hooks';
import { useFeatureFlagsCXC } from '@collections-mvp/hooks';
import { IFlagsmithFeature, IFlagsmithTrait } from 'flagsmith/types';
import setAuthToken from '../../../src/helpers/setAuthToken';
import { User, useUser } from '../../shared/hooks/useUser';
import { useRouterProxy } from '../adapter/useRouteProxy';
import { useCheckAuthExpiration } from './hooks/useCheckAuthExpiration';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import publicPaths from '../common/publicPaths';
import getPathnameFromUrl from '../libs/getPathnameFromUrl';
import urlExists from '../libs/urlExists';

const redirectIfNeeded = (
  user: User,
  router: NextRouter,
  setIsRedirecting: Dispatch<SetStateAction<boolean>>,
  isMaintenance: boolean
) => {
  const { asPath: routerPathname } = router;

  if (!router.isReady || !urlExists(routerPathname)) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { destination } = useRouterProxy(user, router.asPath, isMaintenance);
  const { query } = router;
  delete query.dynamicRoutes;

  if (destination) {
    setIsRedirecting(true);
    const pathname = getPathnameFromUrl(router.asPath);
    if (!publicPaths.has(pathname)) {
      const url = window.location.href.split('?')[0];
      query.redirect = url;
    }
    router.push({
      pathname: destination,
      query,
    });
  } else {
    setIsRedirecting(false);
  }
  return destination;
};

/**
 *
 * Redirects to /404 if the feature flag related with the current pathname isn't enabled
 */
const redirectOnDisabledFeature = async (
  router: NextRouter,
  flagsByPathname: { [key: string]: IFlagsmithFeature & IFlagsmithTrait },
  setIsRedirecting: Dispatch<SetStateAction<boolean>>
): Promise<void> => {
  const { pathname } = router;
  if (!flagsByPathname[pathname]) return;
  if (!flagsByPathname[pathname].enabled) {
    setIsRedirecting(true);
    await router.push('/404');
    setIsRedirecting(false);
  }
};

export function RouterProxy({
  children,
  isFlagsmithInitialized,
}: {
  children: JSX.Element;
  isFlagsmithInitialized: boolean;
}) {
  const router = useRouter();
  const user = useUser();
  const isMounted = useIsMounted();
  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE === 'true';
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { cxcMvpFlag } = useFeatureFlagsCXC();
  const { internationalPaymentsFlag } = useFeatureFlags();
  const flagsByPathname = {
    '/international-payments': internationalPaymentsFlag,
    '/collections-mvp': cxcMvpFlag,
    '/collections-mvp/history': cxcMvpFlag,
    '/collections-mvp/xepelin': cxcMvpFlag,
  };

  if (!isMounted?.current && !user.isLoading && isFlagsmithInitialized) {
    isMounted.current = true;
    redirectIfNeeded(user, router, setIsRedirecting, isMaintenance);
    setAuthToken(user.status.token.value);
  }

  useCheckAuthExpiration();

  useEffect(() => {
    if (isFlagsmithInitialized) {
      setIsRedirecting(false);
      redirectIfNeeded(user, router, setIsRedirecting, isMaintenance);
      redirectOnDisabledFeature(router, flagsByPathname, setIsRedirecting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, isFlagsmithInitialized]);

  if (isRedirecting || user.isLoading || !isFlagsmithInitialized) {
    return <Loader fullscreen />;
  }
  return children;
}
