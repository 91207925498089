import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { useGetCreditLine } from '../modules/payments/modules/credit-line/hooks/use-get-credit-line';
import { useGetCountLastDaysOperations } from '../modules/payments/hooks';

const ProductFruits = dynamic(() => import('react-product-fruits'), {
  ssr: false,
});

function ProductFruitsTour() {
  const router = useRouter();

  // Disabled default URL detection and notify ProductFruits on page change
  // https://help.productfruits.com/en/article/developers-integration-react-nextjs#routing-in-nextjs
  // @ts-ignore
  if (global.window) window.pfDisableUrlChangeDetection = true;
  const productFruitsPageChanged = () =>
    // @ts-ignore
    typeof window.productFruits !== 'undefined' &&
    // @ts-ignore
    typeof window.productFruits.pageChanged !== 'undefined' &&
    // @ts-ignore
    window.productFruits.pageChanged();

  useEffect(() => {
    router.events.on('routeChangeComplete', productFruitsPageChanged);

    return () => {
      router.events.off('routeChangeComplete', productFruitsPageChanged);
    };
  }, [router.events]);

  const userStateEmail = useSelector(
    (state: RootState) => state.authState.user.email
  );
  const buinessStateIdentifier = useSelector(
    (state: RootState) => state.authState.business.identifier
  );
  const businessStateProps = useSelector(
    (state: RootState) => state.businessState?.business
  );

  const { status: creditLineStatus, total: creditLineAmount } =
    useGetCreditLine({
      businessId: businessStateProps?.id,
    });

  const countLast90DaysOperations = useGetCountLastDaysOperations({
    businessId: businessStateProps?.id,
    period: 90,
  });

  const userInfo = {
    username: buinessStateIdentifier,
    email: userStateEmail,
    props: {
      ...businessStateProps,
      creditLineStatus,
      creditLineAmount,
      countLast90DaysOperations,
    },
  };

  if (!global.window || !userInfo.username) return null;

  return (
    <ProductFruits
      // @ts-ignore
      projectCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_PROJECT_CODE}
      language="es"
      {...userInfo}
    />
  );
}

export default ProductFruitsTour;
