import axios from 'axios';
import tracker from './tracker';
import { captureAxiosExceptionsInSentry } from './captureAxiosExceptionsInSentry';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    country: process.env.NEXT_PUBLIC_COUNTRY_ID,
  },
});

instance.interceptors.request.use(tracker.onRequest);

instance.interceptors.response.use();
instance.interceptors.response.use(
  tracker.onResponse,
  captureAxiosExceptionsInSentry
);
export default instance;
