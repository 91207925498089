import axios from '../../../../helpers/axios';

interface CountLastDaysOperations {
  lastDaysOperations: number;
}

interface GetCountLastDaysOperationsRequest {
  businessId: number;
  period: number;
}

const getCountLastDaysOperationsRequest = async ({
  businessId,
  period,
}: GetCountLastDaysOperationsRequest): Promise<CountLastDaysOperations> => {
  try {
    const { data } = await axios.get(
      `api/payment/business/${businessId}/order/count-operations?days=${period}`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

export { getCountLastDaysOperationsRequest };
