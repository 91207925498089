import { useQuery } from 'react-query';
import { CreditLine } from '@payments/types';
import { getCreditLineRequest } from '../infrastructure/repository/queries';

interface UseGetCreditLineParams {
  businessId: number;
  handleError?: () => void;
}

interface UseGetCreditLineResponse extends CreditLine {
  isLoading: boolean;
}

const useGetCreditLine = ({
  businessId,
  handleError = () => undefined,
}: UseGetCreditLineParams): UseGetCreditLineResponse => {
  const { data: creditLine, isLoading } = useQuery(
    ['getCreditLine', businessId],
    async () => getCreditLineRequest({ businessId }).catch(handleError),
    { refetchOnWindowFocus: false, retry: false, enabled: !!businessId }
  );

  const { total = 0, available = 0, used = 0, status } = creditLine || {};

  return { total, available, used, status, isLoading };
};

export { useGetCreditLine };
