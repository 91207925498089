import { createTheme } from '@material-ui/core';
import palette from './palette';
import oldTypography from './typography';
import overrides from './overrides';
import { ThemeOptions } from './types';

const theme: ThemeOptions = {
  palette,
  typography: oldTypography,
  overrides,
};

export default theme;

export const muiTheme = createTheme(theme);
