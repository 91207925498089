import { CreditLine } from '@payments/types';
import axios from '../../../../../../../helpers/axios';

interface GetCreditLineParams {
  businessId: number;
}

const getCreditLineRequest = async ({
  businessId,
}: GetCreditLineParams): Promise<CreditLine> => {
  try {
    const { data } = await axios.get(
      `api/business/${businessId}/creditline/balance`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

export { getCreditLineRequest };
