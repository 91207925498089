import { useEffect, useRef } from 'react';
import { setNewEventId, clearEventId } from '@finance/services/tracker';
import { historyProxy } from '../hoc/HistoryProxy';

export function ProviderFinance({ children }) {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      setNewEventId();
      initialRender.current = false;
    }

    return () => {
      clearEventId();
      historyProxy.deleteHistory();
    };
  }, []);

  return children;
}
