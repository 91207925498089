// eslint-disable-next-line max-classes-per-file
class HttpError extends Error {
  constructor(message) {
    super(message);
    this.name = 'HttpError';
  }
}

export class ClientError extends HttpError {
  constructor(message) {
    super(message);
    this.name = 'ClientError';
  }
}

export class ServerError extends HttpError {
  constructor(message) {
    super(message);
    this.name = 'ServerError';
  }
}
