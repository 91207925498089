export const SEGMENT_EVENTS = {
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_AUTHENTICATED: 'Account Authenticated',
  PAYMENT_INBOX_VIEWED: 'Payments Inbox Viewed',
  PAYMENT_SCHEDULED_VIEWED: 'Payments Scheduled Viewed ',
  PAYMENT_PAID_VIEWED: 'Payments Paid Viewed',
  PAYMENT_ARCHIVED_VIEWED: 'Payments Archived Viewed',
  PAYMENT_INVOICE_SELECTED: 'Payments Invoices Selected',
  PAYMENT_DATE_COMPLETED: 'Payments Date Completed',
  PAYMENT_NAME_COMPLETED: 'Payments Name Completed',
  PAYMENT_ORDER_SCHEDULED: 'Payments Order Scheduled',
  PAYMENT_INVOICES_SCHEDULED_CLICKED: 'Payments Flow Started',
  PAYMENT_PAY_NOW: 'Payments Overview Accepted',
  PAYMENT_METHOD: 'Payments Method Selected',
  PAYMENT_ORDER_PAID: 'Payments Order Paid',
  PAYMENT_SUBMITTED: 'PAYMENT_SUBMITTED',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
  PAYMENT_EXITED: 'PAYMENT_EXITED',
  PAYMENTS_INVOICE_BULK_OPENED: 'Payments bulk invoice viewed',
  PAYMENTS_INVOICE_BULK_USED: 'Payments bulk invoice successfully completed',
  PAYMENTS_RECEIPTS_DOWNLOADED: 'PAYMENTS_RECEIPTS_DOWNLOADED',
  PAYMENTS_INBOX_INVOICES_CHECKED: 'PAYMENTS_INBOX_INVOICES_CHECKED',
  PAYMENTS_INVOICE_DISCOUNT_ADDED: 'PAYMENTS_INVOICE_DISCOUNT_ADDED',
  PAYMENTS_BULK_SUPPLIERS_USED: 'PAYMENTS_BULK_SUPPLIERS_USED',
  PAYMENTS_PAYMENT_HUB_INFORMATION_CONFIRMED:
    'PAYMENTS_PAYMENT_HUB_INFORMATION_CONFIRMED',
  PAYMENTS_TERM_DATE_CONFIRMED: 'PAYMENTS_TERM_DATE_CONFIRMED',
  PAYMENTS_VIEWED: 'PAYMENTS_VIEWED',
  LEGAL_DOCUMENT_DOWNLOAD: 'Legal Document Download',
  AR_BACK_TO_STEP_ONE: 'AR_BACK_TO_STEP_ONE',
  AR_UPLOAD_PFX: 'AR_UPLOAD_PFX',
  ORDER_SUBMIT_SUCCESS: 'ORDER_SUBMIT_SUCCESS',
  ORDER_STARTED: 'ORDER_STARTED',
  ORDER_SUBMITTED: 'ORDER_SUBMITTED',
  AR_INVOICE_SELECTION: 'AR_INVOICE_SELECTION',
  AR_SEARCH_USED: 'AR_SEARCH_USED',
  AR_FILTER_USED: 'AR_FILTER_USED',
  AR_DATE_CHANGED: 'AR_DATE_CHANGED',
  AR_INVOICE_DELETED: 'AR_INVOICE_DELETED',
  AR_REDIRECT_CONFIRMATION: 'AR_REDIRECT_CONFIRMATION',
  AR_REDIRECT_OTHER_PRODUCT: 'AR_VIEW_OTHER_TYPE_OF_INVOICE',
  AR_SEARCH_NO_RESULTS: 'AR_SEARCH_NO_RESULTS',
  AR_REFRESH_INVOICES: 'AR_REFRESH_INVOICES',
  AR_VIEW_DIRECT_STEP_TWO: 'AR_VIEW_DIRECT_STEP_TWO',
  AR_CREATE_ORDER_BY_URL: 'AR_CREATE_ORDER_BY_URL',
  AR_STEP_TWO_INVOICES_NOT_FINANCIABLES:
    'AR_STEP_TWO_INVOICES_NOT_FINANCIABLES',
  AR_DOWNLOAD_SIMULATION_PDF: 'AR_DOWNLOAD_SIMULATION_PDF',
  AR_VIEW_FD: 'Viewed_FD_tab',
  AR_VIEW_PP: 'Viewed_PP_tab',
  AR_CHANGE_VISIBILITY_STEP_TWO: 'AR_CHANGE_VISIBILITY_STEP_TWO',
  AR_DOWNLOAD_CSV_BUTTON_USED: 'AR_DOWNLOAD_CSV_BUTTON_USED',
};

export default SEGMENT_EVENTS;
