export const DIRECT_FINANCING = 'DIRECT_FINANCING';
export const DIRECT_FINANCING_LABEL = 'Financiamiento directo';
export const EARLY_PAYMENT = 'EARLY_PAYMENT';
export const EARLY_PAYMENT_LABEL = 'Pronto pago';
export const CONFIRMING = 'CONFIRMING';
export const CONFIRMING_LABEL = 'Confirming';
export const PAYMENT_LABEL = 'Payment';

export const COUNTRY_CODE_MX = 'MX';
export const COUNTRY_CODE_CL = 'CL';
export const AVAILABLE_COUNTRIES = [COUNTRY_CODE_CL, COUNTRY_CODE_MX];

export const PREVIRED_LABEL = 'Previred';
export const DICOM_LABEL = 'Dicom';
export const TGR_LABEL = 'TGR';
export const NOMINA_PERSONAL_LABEL = 'Nómina de personal';
export const BURO_CREDITO_LABEL = 'Buró de crédito';

export const LONG_DATE_FORMAT = 'DD MMMM YYYY';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const MINIMUM_FINANCEABLE_DAYS = 15;

export const CART_STEPS = [
  'Selecciona tus facturas',
  'Elige fecha de pago',
  'Envía la solicitud',
];

export const CARDS_INFO = {
  CARD_TGR: {
    code: 'CARD_TGR',
    title: TGR_LABEL,
    bodyText: '',
  },
  CARD_DICOM: {
    code: 'CARD_DICOM',
    title: DICOM_LABEL,
    bodyText: '',
  },
  CARD_PREVIRED: {
    code: 'CARD_PREVIRED',
    title: PREVIRED_LABEL,
    bodyText:
      'Para mostrarte aquí tu información en tiempo real debes ingresar tus datos de Previred.',
  },
  CARD_NOMINA_PERSONAL: {
    code: 'CARD_NOMINA_PERSONAL',
    title: NOMINA_PERSONAL_LABEL,
    bodyText:
      'Para mostrarte aquí tu información en tiempo real debes ingresar tus datos de XXXX',
  },
  CARD_BURO_CREDITO: {
    code: 'CARD_BURO_CREDITO',
    title: BURO_CREDITO_LABEL,
    bodyText: '',
  },
  CARD_SURPLUS: {
    code: 'CARD_SURPLUS',
  },
};

export const JOB_STATUSES = {
  QUEUE: 'QUEUE',
  WORKING: 'WORKING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const ONBOARDING_STEP_CASES = {
  ADD_TAX_CREDENTIALS: 'ADD_TAX_CREDENTIALS',
  AUTHORIZE_DEBT_SERVICE: 'AUTHORIZE_DEBT_SERVICE',
  ADD_LEGAL_REPRESENTATIVE: 'ADD_LEGAL_REPRESENTATIVE',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
};

const FLOW_STEPS_ORDER = {
  IN_REVIEW: {
    index: 0,
    active: 1,
    label: 'En revisión',
    variant: 'completed',
    icon: 'check',
    size: 'small',
  },
  APPROVED_SUCCESS: {
    index: 1,
    active: 1,
    label: 'Aprobado',
    variant: 'completed',
    icon: 'check',
    size: 'small',
  },
  APPROVED_DEFAULT: {
    index: 1,
    active: 0,
    label: 'Aprobado',
    variant: 'disabled',
    icon: 'check',
    size: 'xs',
  },
  REJECTED: {
    index: 1,
    active: 1,
    label: 'Rechazado',
    variant: 'error',
    icon: 'error',
    size: 'small',
  },
  PENDING_DOCUMENTS: {
    index: 1,
    active: 1,
    label: 'Documentos',
    variant: 'warning',
    icon: 'check',
    size: 'small',
  },
  TRANSFERRED_SUCCESS: {
    index: 2,
    active: 1,
    label: 'Transferido',
    variant: 'completed',
    icon: 'check',
    size: 'small',
  },
  TRANSFERRED_DEFAULT: {
    index: 2,
    active: 0,
    label: 'Transferido',
    variant: 'disabled',
    icon: 'check',
    size: 'xs',
  },
  CRITICAL_STATE: {
    index: 2,
    active: 1,
    label: 'Estado Crítico',
    variant: 'warning',
    icon: 'error',
    size: 'small',
  },
  DELINQUENT: {
    index: 2,
    active: 1,
    label: 'Mora',
    variant: 'warning',
    icon: 'error',
    size: 'small',
  },
  PAID_SUCCESS: {
    index: 3,
    active: 1,
    label: 'Pagado',
    variant: 'completed',
    icon: 'check',
    size: 'small',
  },
  DEFAULT: {
    index: 3,
    active: 1,
    label: 'No pagado',
    variant: 'error',
    icon: 'error',
    size: 'small',
  },
  PAID_DEFAULT: {
    index: 3,
    active: 0,
    label: 'Pagado',
    variant: 'disabled',
    icon: 'check',
    size: 'xs',
  },
  RENEGOTIATION: {
    index: 3,
    active: 1,
    label: 'Renegociado',
    variant: 'warning',
    icon: 'error',
    size: 'small',
  },
};

export const FLOWS_ORDER = {
  IN_REVIEW: [
    { ...FLOW_STEPS_ORDER.IN_REVIEW, size: 'default' },
    FLOW_STEPS_ORDER.APPROVED_DEFAULT,
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  APPROVED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    { ...FLOW_STEPS_ORDER.APPROVED_SUCCESS, size: 'default' },
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  REJECTED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    { ...FLOW_STEPS_ORDER.REJECTED, size: 'default' },
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  PENDING_DOCUMENTS: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    { ...FLOW_STEPS_ORDER.PENDING_DOCUMENTS, size: 'default' },
    FLOW_STEPS_ORDER.TRANSFERRED_DEFAULT,
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  CRITICAL: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    { ...FLOW_STEPS_ORDER.CRITICAL_STATE, size: 'default' },
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  ARREAR: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    { ...FLOW_STEPS_ORDER.DELINQUENT, size: 'default' },
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  ACTIVE: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    { ...FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS, size: 'default' },
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  TRANSFERRED: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    { ...FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS, size: 'default' },
    FLOW_STEPS_ORDER.PAID_DEFAULT,
  ],
  PAID: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    { ...FLOW_STEPS_ORDER.PAID_SUCCESS, size: 'default' },
  ],
  RENEGOTIATION: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    { ...FLOW_STEPS_ORDER.RENEGOTIATION, size: 'default' },
  ],
  DEFAULT: [
    FLOW_STEPS_ORDER.IN_REVIEW,
    FLOW_STEPS_ORDER.APPROVED_SUCCESS,
    FLOW_STEPS_ORDER.TRANSFERRED_SUCCESS,
    { ...FLOW_STEPS_ORDER.DEFAULT, size: 'default' },
  ],
};

const BASE_STATUSES = {
  IN_REVIEW: 'IN_REVIEW',
  REVIEWED: 'REVIEWED',
  APPEALED: 'APPEALED',
  APPROVED: 'APPROVED',
  APPROVED_FUND: 'APPROVED_FUND',
  VERIFIED: 'VERIFIED',
  TO_DEPOSIT: 'TO_DEPOSIT',
  ACTIVE: 'ACTIVE',
  PAID: 'PAID',
  COMPLETE: 'COMPLETE',
  DEFAULT: 'DEFAULT',
  REJECTED: 'REJECTED',
  POST_VERIFIED: 'POST_VERIFIED',
};

export const ORDER_FACTURAS_STATUSES = {
  ...BASE_STATUSES,
  CEDE: 'CEDE',
  SOFT_DELINQUENCY: 'SOFT_DELINQUENCY',
  HARD_DELINQUENCY: 'HARD_DELINQUENCY',
  DELINQUENT: 'DELINQUENT',
  HARD_COLLECTION: 'HARD_COLLECTION',
  REPLACED: 'REPLACED',
  REJECTED: 'REJECTED',
};

export const ORDER_STATUSES = {
  ...BASE_STATUSES,
  PV_APPROVED: 'PV_APPROVED',
  TRANSFERRED: 'TRANSFERRED',
  RENEGOTIATION: 'RENEGOTIATION',
};

export const ORDER_STATUS_GROUP = {
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  TRANSFERRED: 'TRANSFERRED',
  PAID: 'PAID',
  REJECTED: 'REJECTED',
  RENEGOTIATION: 'RENEGOTIATION',
  ARREAR: 'ARREAR',
  CRITICAL: 'CRITICAL',
};

export const ORDER_STATUS_GROUP_LABEL = {
  IN_REVIEW: 'En revisión',
  APPROVED: 'Aprobado',
  TRANSFERRED: 'Transferido',
  PAID: 'Pagado',
  REJECTED: 'Rechazado',
  RENEGOTIATION: 'Renegociado',
  ARREAR: 'En mora',
  CRITICAL: 'Estado crítico',
};

export const VALIDATION_TYPES = {
  REQUIRED: 'required',
  VALID_RUT: 'isValidRut',
  VALID_EMAIL: 'isEmail',
  VALID_PHONE: 'isValidPhone',
  MATCH_CONFIRM_PASSWORD: 'isPasswordMatch',
};

export const VALIDATION_MESSAGES = {
  REQUIRED_MSG: 'Campo requerido',
  VALID_RUT_MSG: 'Rut incorrecto',
  VALID_EMAIL_MSG: 'Email incorrecto',
  VALID_PHONE_MSG: 'Por favor ingresa un número válido (ejemplo: +56123456789)',
  MATCH_CONFIRM_PASSWORD_MSG: 'La contraseña no coincide',
};

export const WS_COMMANDS = {
  CREATE_WS_CONNECTION: 'CREATE_WS_CONNECTION',
  TAX_SCRAPE_DATA: 'TAX_SCRAPE_DATA',
  WALLET_BALANCE_CHANGED: 'WALLET_BALANCE_CHANGED',
};

export const TYPOGRAPHY_VARIANTS = [
  'body1',
  'body2',
  'body3',
  'button',
  'tiny',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'inherit',
  'display1',
  'display2',
];

export const TYPOGRAPHY_COLORS = [
  'error',
  'inherit',
  'initial',
  'primary',
  'secondary',
  'textPrimary',
  'textSecondary',
  'contrast1',
  'contrast2',
];

export const FONT_WEIGHTS = ['fontWeightBold'];

export const CIVILSTATUS = [
  { country: 'CL', value: 'SOLTERO', label: 'Soltero/a' },
  {
    country: 'CL',
    value: 'CASADO_CONYUGAL',
    label: 'Casado/a con sociedad conyugal',
  },
  { country: 'CL', value: 'CASADO', label: 'Casado/a' },
  { country: 'CL', value: 'DIVORCIADO', label: 'Divorciado/a' },
  { country: 'CL', value: 'SEPARADO', label: 'Separado/a' },
  { country: 'CL', value: 'VIUDO', label: 'Viudo/a' },
  { country: 'MX', value: 'SOLTERO', label: 'Soltero/a' },
  { country: 'MX', value: 'CASADO', label: 'Casado/a' },
  { country: 'MX', value: 'VIUDO', label: 'Viudo/a' },
  {
    country: 'MX',
    value: 'SEPARACION_EN_PROCESO_JUDICIAL',
    label: 'Separación en Proceso Judicial',
  },
  { country: 'MX', value: 'DIVORCIADO', label: 'Divorciado/a' },
  { country: 'MX', value: 'CONCUBINATO', label: 'Concubinato' },
];

export const TRANSFORMED_ACCOUNT_TYPES = {
  CUENTA_CORRIENTE: 'Cuenta corriente',
  CUENTA_VISTA: 'Cuenta vista',
  CUENTA_AHORRO: 'Cuenta ahorro',
  CUENTA_CLABE: 'Cuenta CLABE',
};

export const BANK_ACCOUNT_VERIFICATION_STATUS = {
  VERIFYING: 'Pendiente',
  VERIFIED: 'Aprobado',
  INVALID: 'Rechazada',
};

// ACCOUNT TYPES

export const ACCOUNT_TYPES = {
  CL: [
    {
      value: 'CUENTA_CORRIENTE',
      label: TRANSFORMED_ACCOUNT_TYPES.CUENTA_CORRIENTE,
    },
    { value: 'CUENTA_VISTA', label: TRANSFORMED_ACCOUNT_TYPES.CUENTA_VISTA },
    { value: 'CUENTA_AHORRO', label: TRANSFORMED_ACCOUNT_TYPES.CUENTA_AHORRO },
  ],
  MX: [
    { value: 'CUENTA_CLABE', label: TRANSFORMED_ACCOUNT_TYPES.CUENTA_CLABE },
  ],
};

export const DISCOUNT_REASONS = [
  {
    key: 'PAGO_FACTURA',
    label: 'Pago de factura',
  },
  {
    key: 'PAGO_CREDITO',
    label: 'Pago de crédito',
  },
  {
    key: 'PAGO_CXP',
    label: 'Pago de CxP',
  },
  { key: 'CAMBIO_FACTURA', label: 'Cambio de factura' },
  { key: 'LIFE_INSURANCE_PF', label: 'Seguro de Vida - PF' },
  { key: 'CREDIT_NOTE', label: 'Nota de Crédito' },
  { key: 'TRANSFER_ERROR', label: 'Error en Giro' },
  {
    key: 'ERROR_GIRO',
    label: 'Error en el giro',
  },
  {
    key: 'ANULACION',
    label: 'Anulación',
  },
  {
    key: 'NOTA_CREDITO',
    label: 'Nota de Crédito',
  },
];
export const FIELD_REQUIRED = 'Campo requerido';
export const INVALID_EMAIL = 'Email inválido';
export const VALIDATOR_EMAIL = 'isEmail';
export const VALIDATOR_REQUIRED = 'required';

export const LINKS = {
  CL: {
    location:
      'https://www.google.com/maps/place/Mariano+S%C3%A1nchez+Fontecilla+310,+Las+Condes,+Regi%C3%B3n+Metropolitana/@-33.4199867,-70.6005913,17z/',
    solutions: 'https://www.xepelin.com/soluciones',
    aboutUs: 'https://www.xepelin.com/sobre-nosotros',
    resources: 'https://www.xepelin.com/recursos',
    help: 'https://www.xepelin.com/ayuda',
    jobBoard:
      'https://www.notion.so/xepelin/Job-Board-0cd3c52cd3ba4f58ad55fb704993642b',
    terms: 'https://xepelin.com/ayuda#terminos-condiciones',
    privacy: 'https://www.xepelin.com/politica-de-privacidad/',
  },
  MX: {
    location:
      'https://www.google.com/maps/place/Local+8/@19.5135912,-99.2328164,20z/data=!4m6!3m5!1s0x85d202d6353687d1:0x14392e30c72b0c5c!4b1!8m2!3d19.5138521!4d-99.2328777',
    solutions: 'https://www.xepelin.com/mx/financiamiento',
    aboutUs: 'https://www.xepelin.com/mx/sobre-nosotros',
    resources: 'https://www.xepelin.com/mx/recursos',
    help: 'https://www.xepelin.com/mx/ayuda',
    jobBoard:
      'https://www.notion.so/xepelin/Job-Board-0cd3c52cd3ba4f58ad55fb704993642b',
    terms: 'https://www.xepelin.com/mx/terminos-y-condiciones',
    privacy: 'https://www.xepelin.com/mx/aviso-de-privacidad',
  },
};

// DOCUMENT ACTIONS
export const UPLOAD = 'UPLOAD';
export const DOWNLOAD = 'DOWNLOAD';
export const MIFIEL_ON = 'MIFIEL_ON';
export const MIFIEL_OFF = 'MIFIEL_OFF';
export const SIGN = 'SIGN';
export const LINK = 'LINK';

export const BANK_ACCOUNTS_STATE = {
  VERIFIED: 'VERIFIED',
  INVALID: 'INVALID',
  VERIFYING: 'VERIFYING',
};

export const REJECTED_DOC_REASONS = {
  CL: [
    {
      type: 'legal',
      label: 'Documento sin firma de Representante Legal',
      value: 'DOC_WITHOUT_SIGN_LEGAL_REPRESENTATIVE',
    },
    {
      type: 'legal',
      label: 'Documento sin fecha',
      value: 'DOC_WITHOUT_DATE',
    },
    {
      type: 'legal',
      label: 'Documento sin firma',
      value: 'DOC_WITHOUT_SIGN',
    },
    {
      type: 'legal',
      label: 'Dirección incompleta',
      value: 'INCOMPLETE_ADDRESS',
    },
    {
      type: 'legal',
      label: 'Razón social o Nombre de representante incompleto',
      value: 'BUSINESS_OR_REPRESENTATIVE_INCOMPLETE',
    },
  ],
  MX: [
    {
      type: 'legal',
      label: 'Pendiente boleta de inscripción RPC',
      value: 'RPC_PENDING_REGISTRATION',
    },
    {
      type: 'legal',
      label:
        'Representante legal sin poder de firmar títulos ni operaciones de crédito',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_TITLES_SIGN_OR_CREDIT_OPERATIONS',
    },
    {
      type: 'legal',
      label: 'Representante legal sin poder administrativo',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_ADM_POWER',
    },
    {
      type: 'legal',
      label: 'Representante legal sin ningún poder',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_POWER',
    },
  ],
};
