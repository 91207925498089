import axios from 'axios';
import tracker from '../../../helpers/tracker';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CXC_MVP_API_URL,
  headers: {
    country: process.env.NEXT_PUBLIC_COUNTRY_ID,
  },
});
instance.interceptors.request.use(tracker.onRequest);

instance.interceptors.response.use();

instance.interceptors.response.use(tracker.onResponse);

export default instance;
