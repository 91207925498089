import { useEffect, useRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_light.min';
import { Box, makeStyles } from '@material-ui/core';
import { useNameComponent } from 'hooks';
import animationData from '../assets/loader-animation.json';

interface IEmptyMessageProps {
  fullscreen?: boolean;
  fullscreenWithTopbar?: boolean;
  contained?: boolean;
  width?: number | string;
  dataXComponent?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: ({ contained }: IEmptyMessageProps) =>
      contained ? 'rgba(255,255,255,0.5)' : '',
    height: ({ fullscreen, fullscreenWithTopbar }: IEmptyMessageProps) => {
      if (fullscreen) return '100vh';
      if (fullscreenWithTopbar) return 'calc(100vh - 64px)';
      return 'auto';
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function EmptyMessage({
  fullscreen,
  fullscreenWithTopbar,
  contained,
  width = 140,
  dataXComponent = null,
}: IEmptyMessageProps) {
  const lottieElement = useRef<HTMLDivElement>(null);
  const classes = useStyles({ fullscreen, fullscreenWithTopbar, contained });

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieElement.current!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });

    return () => lottie.stop();
  }, []);

  return (
    <Box
      className={classes.container}
      borderRadius={contained && 8}
      padding={contained && 8}
    >
      <div
        ref={lottieElement}
        data-xcomponent={useNameComponent(dataXComponent)}
        style={{ width }}
      />
    </Box>
  );
}

export default EmptyMessage;
