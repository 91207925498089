import * as Sentry from '@sentry/nextjs';
import { ClientError, ServerError } from './httpErrors';

export const captureAxiosExceptionsInSentry = error => {
  const status = error?.response?.status;
  const errorCode = error?.response?.data?.errorCode;

  if (status > 400 && status < 500) {
    Sentry.captureMessage(new ClientError(error));
  } else if (status >= 500 && errorCode) {
    Sentry.captureException(new ServerError(errorCode));
  } else {
    Sentry.captureException(new Error(error));
  }
  return Promise.reject(error);
};
