import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from '../types';

interface CurrentLanguage {
  currentLanguage: SupportedLanguages;
}

function getCurrentLanguage(): CurrentLanguage {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation('home');
  const currentLanguage = i18n.language as SupportedLanguages;

  return {
    currentLanguage,
  };
}

export default getCurrentLanguage;
