import axios from './axios';
import axiosCxcMvp from '../integration-services/xepelin-collections-mvp/helpers/axios_MVP_CXC';

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axiosCxcMvp.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export default setAuthToken;
