import { useQuery } from 'react-query';
import { getCountLastDaysOperationsRequest } from '../infrastructure/queries';

interface UseGetCountLastDaysOperationsParams {
  businessId: number;
  period?: number;
}

const useGetCountLastDaysOperations = ({
  businessId,
  period = 90,
}: UseGetCountLastDaysOperationsParams): number => {
  const { data } = useQuery(
    ['countLastDaysOperations', businessId, period],
    async () => getCountLastDaysOperationsRequest({ businessId, period }),
    { refetchOnWindowFocus: false, retry: false, enabled: !!businessId }
  );

  const { lastDaysOperations } = data || {};

  return lastDaysOperations;
};

export { useGetCountLastDaysOperations };
