import { useFlags } from 'flagsmith/react';
import { IFlagsmithFeature, IFlagsmithTrait } from 'flagsmith/types';

type FeatureFlag = IFlagsmithFeature & IFlagsmithTrait;

interface UseFeatureFlagsResponse {
  cxcMvpFlag: FeatureFlag;
}

const useFeatureFlagsCXC = (): UseFeatureFlagsResponse => {
  const { cxc_mvp: cxcMvpFlag } = useFlags(['cxc_mvp']);

  return {
    cxcMvpFlag,
  };
};

export default useFeatureFlagsCXC;
