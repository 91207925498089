import { useFlags } from 'flagsmith/react';
import { IFlagsmithFeature, IFlagsmithTrait } from 'flagsmith/types';

type FeatureFlag = IFlagsmithFeature & IFlagsmithTrait;

interface UseFeatureFlagsResponse {
  invoicesFilterFlag: FeatureFlag;
  internationalPaymentsFlag: FeatureFlag;
}

const useFeatureFlags = (): UseFeatureFlagsResponse => {
  const {
    ap_strategic_suppliers_filter: invoicesFilterFlag,
    ap_international_payments: internationalPaymentsFlag,
  } = useFlags(['ap_strategic_suppliers_filter', 'ap_international_payments']);

  return {
    invoicesFilterFlag,
    internationalPaymentsFlag,
  };
};

export default useFeatureFlags;
