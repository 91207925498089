/* eslint-disable no-underscore-dangle */
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import {
  loginWithToken,
  setCurrentUser,
} from '../../../../src/store/actions/authenticationActions';
import { AccessToken } from '../../domain';

import { AuthStore } from '../../domain/AuthStore';
import { BusinessInfo } from '../../domain/BusinessInfo';
import { AccessTokenMap } from '../mappers/accessToken.map';

export const useAuthStore = (): AuthStore => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.authState.user);

  const business = useSelector((state: RootState) => state.authState.business);

  const isRehydrated = useSelector(
    (state: { _persist: { rehydrated: boolean } }) => state._persist.rehydrated
  );

  const isAuthenticated = useSelector(
    (state: RootState) => state.authState?.isAuthenticated
  );

  const isCompleteUserRegistered = useSelector((state: RootState) =>
    Boolean(state.authState?.user?.id)
  );

  const isBusinessRegistered = useSelector((state: RootState) =>
    Boolean(state.authState?.business?.id)
  );

  const isTaxRegistered = useSelector(
    (state: {
      taxServiceState: { taxStatus: { isCredentialActive: boolean } };
    }) => state.taxServiceState.taxStatus.isCredentialActive
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [detailErrors, setDetailErrors] = useState([]);

  const countryId = process.env
    .NEXT_PUBLIC_COUNTRY_ID as BusinessInfo['countryId'];

  const token = useSelector((state: RootState) =>
    AccessTokenMap.toDomain(state.authState)
  );

  const setUserData = (newToken: AccessToken) => {
    const tokenInfo: { data: any } = jwtDecode(newToken.value);
    dispatch(setCurrentUser({ decoded: tokenInfo?.data, token: newToken }));
  };

  const saveAccessToken = async (accessToken: AccessToken) => {
    dispatch(loginWithToken(accessToken.value));
  };

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const clearAuthError = () => {
    setIsAuthError(null);
    setDetailErrors([]);
  };

  return {
    isLoading,
    isRehydrated,
    isAuthError,
    user,
    business,
    token,
    countryId,
    isAuthenticated,
    isBusinessRegistered,
    isTaxRegistered,
    isCompleteUserRegistered,
    saveAccessToken,
    startLoading,
    stopLoading,
    authError,
    setAuthError,
    clearAuthError,
    setUserData,
    detailErrors,
    setDetailErrors,
  };
};
